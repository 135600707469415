<template lang="html">
    <main-layout>
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'General information'"
            >
                <template #headerIcon>
                    <account-multiple />
                </template>
                <template #form>
                    <div class="wizard-general">
                        <general-info-form
                            ref="form"
                            :success-callback="confirm"
                        />
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import GeneralInfoForm from '@/components/GeneralInfoForm.vue'

export default {
    name: 'Index',
    components: {
        MainLayout,
        ProfileFormLayout,
        GeneralInfoForm
    },
    metaInfo: {
        title: 'General Info'
    },
    data() {
        return {
            showModal: false
        }
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.$refs['form'].validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        async confirm() {
            try {
                await this.$refs['form'].confirm()
                this.$router.push({ name: 'profile' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>
